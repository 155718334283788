import {NgModule} from '@angular/core';

import {IconComponent} from '@shared/ui-components/icon/icon.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
	declarations: [IconComponent],
	exports: [IconComponent],
	imports: [MatIconModule],
})
export class IconModule {}
